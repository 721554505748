export const italianTranslation = {
    de: "Tedesco",
    fr: "Francese",
    it: "Italiano",
    nl: "Olandese",

    DE: "Germania",
    FR: "Francia",
    IT: "Italia",
    NL: "Paesi bassi",
    BE: "Belgio",
    CH: "Svizzera",
    AT: "Austria",
    LU: "Lussemburgo",

    yes: "Sì",
    no: "No",
    ok: "OK",
    cancel: "Annulla",
    close: "Chiudi",
    read: "Leggi",
    app_info: "prowin.app - Il portale informativo per le vostre attività di vendita",
    logout_success: "Ti sei disconnesso con successo",
    login_again: "Accedi di nuovo",
    to_prowin_website:"Passare sulla pagina web di proWIN",
    thank_you: "Grazie per la vostra visita, vi auguriamo successo e divertimento con proWIN.",
    go_back_original_account: "Ritorna all'account originale",
    switch_account: "Cambiare account",
    no_subaccount: "Nessun conto secondario disponibile",
    select_country: "Scegliere il paese",
    select_language: "Scegliere la lingua",
    change_country: "Cambiare il paese",
    change_language: "Cambiare la lingua",

    error_back_to_home: "<small>Indietro <a href=\"/\">al dashboard</a>.</small>",
    error_not_found_title: "Pagina non trovata",
    error_not_found_text: "Impossibile trovare la pagina richiesta. Sei sicuro che esista?",
    error_loading_title: "Errore di caricamento",
    error_loading_text: "",
    error_unknown_title: "Errore sconosciuto",
    error_unknown_text: "",
    error_occured: "Si è avverato un errore.",

    dashboard_title: "Benvenuti da proWIN international",
    dashboard_intro: "Microfrontend App Shell - Dashboard",
    demo_page_title: "Pagina di esempio",

    home: "Pagina iniziale",

    pwa_install: "Installare",
    pwa_reinstall: "Ricarica la pagina se desideri vedere nuovamente il pulsante di installazione.",
    pwa_prompt_install: "Ottieni la app di proWIN.",
    pwa_enable_push: "Aggiungi l'app Web alla schermata iniziale per abilitare le notifiche push",

    menu_label: "Menu",
    menu_home: "Pagina iniziale",
    menu_general_start_page: "Pagina iniziale",
    menu_general_faq: "Informazioni",
    menu_general_information: "Informazioni",
    menu_general_events: "Appuntamenti",
    menu_general_competitions: "Concorsi",
    menu_general_products: "Prodotti",
    menu_general_current: "Distribuzione",
    menu_product_information: "Informazioni sui prodotti",
    menu_product_shop: "Shop",
    menu_product_complaints: "Reclami",
    menu_general_myprowin: "Il mio proWIN",
    menu_myprowin_parties: "Party",
    menu_myprowin_distribution_application: "Contratto di vendita",
    menu_myprowin_invoices: "Fatture e ricevute",
    menu_myprowin_reporting: "Reporting",
    menu_myprowin_distribution_website: "Sito web di vendita",
    menu_myprowin_planning: "Strumento di pianificazione",
    menu_elearning: "E-Learning",
    menu_media: "proWIN Media",

    menu_subline_product_marketing: "Ordinazione di materiale pubblicitario",
    menu_subline_product_info: "Informazioni generali sul prodotto",
    menu_subline_reporting: "Valutazioni dalla mia struttura",
    menu_subline_reclamation: "Reclamare sui prodotti",
    menu_subline_shop: "Ordinare prodotti",
    menu_subline_media: "Il grande portale video",
    menu_subline_academy: "Scopri di più sul lavoro di consulenza",
    menu_subline_party_planning: "Pianificare il mio prossimo party",
    menu_subline_structure: "Creare e gestire gli appuntamenti all'interno della mia struttura",
    menu_subline_vp_webseite: "Modifica il sito web del tuo partner di vendita",
    menu_subline_faq: "Base di conoscenza per riferimento",

    menu_name_product_marketing: "Shop marketing",
    menu_name_product_info: "Informazioni sul prodotto",
    menu_name_reporting: "Reporting",
    menu_name_blumer: "Blumer",
    menu_name_reclamation: "Reclami",
    menu_name_shop: "Shop",
    menu_name_media: "proWIN Media",
    menu_name_academy: "Accademia on-line",
    menu_name_party_planning: "Pianificazione dei party",
    menu_name_vp_webseite: "La mia pagina VP",
    menu_name_structure: "Date della struttura",
    menu_name_faq: "Conoscenza delle vendite",

    user_menu_latest: "Attuale",
    user_menu_knowledge: "Informazioni",
    user_menu_dark_mode: "Dark Mode",
    user_menu_light_mode: "Light Mode",
    user_menu_profile: "Profilo",
    user_menu_tasks: "Compiti",
    user_menu_select_language: "Cambiare la lingua",
    user_menu_my_account: "Il mio conto",
    user_menu_settings: "Impostazioni",
    user_menu_messages: "Notizie",
    user_menu_help: "Aiuto",
    user_menu_contact: "Contatto",
    user_menu_logout: "Disconetti",
    user_menu_language: "Lingua",
    user_menu_country: "Paese",
    user_menu_dashboard: "Dashboard",
    user_menu_salespartner_edit: "Modifica della pagina VIP",
    user_menu_app_install: "Installare la app",

    footer_copy: "{{currentYear}} proWIN international",

    oauth_logout_headline: "Logout",
    oauth_logout_text: "Logout effettuato",

    link_imprint_href: "/informazione legale",
    link_imprint_label: "Informazione legale",
    link_privacy_href: "/protezione dati",
    link_privacy_label: "Protezione dati",
    link_help_href: "/help",
    link_help_label: "Aiuto",
    link_contact_href: "/contact",
    link_contact_label: "Contatto",
    imprint_headline: "Informazione legale",
    privacy_headline: "Protezione dati",

    notifications_choice_title: "Notifiche push",
    notifications_ask_to_get_notificatons: "Vuoi essere informato tramite notifiche push?",
    notifications_ask_to_get_authorization: "Per ricevere le notifiche push, devi abilitarle nel tuo browser.",
    notifications_choice_intro: "Seleziona per quali argomenti deve essere inviata una notifica:",
    notifications_choice_denied: "La notifica è stata bloccata. Ripristina le impostazioni per abilitare le notifiche push.",
    notifications_activated: "Le notifiche sono abilitate.",
    notifications_turn_off: "Per interrompere la ricezione delle notifiche, vai alle impostazioni di questa app Web sul tuo dispositivo e disattiva le notifiche.",

    notification_group_general: "Test 1",
    notification_group_marketing: "Test 2",
    notification_group_other: "Test 3",

    install_web_app_General: "L'installazione della app proWIN al momento non è disponibile in questo browser. Prova un altro browser.",
    install_web_app_dialog_title: "Installa la app proWIN sul tuo dispositivo",

    install_web_app_Firefox: "L'installazione della app proWIN al momento non è disponibile nel browser Firefox.",
    install_web_app_Firefox_Tablet_1: "Fare clic sul menu del browser",
    install_web_app_Firefox_Tablet_2: "Seleziona \"Aggiungi alla schermata iniziale\" dal menu contestuale.",
    install_web_app_Firefox_Tablet_3: "Fare clic sulla \"Pagina iniziale\"",
    install_web_app_Firefox_3: "Fai clic su Installa nella piccola finestra pop-up e vieni chiesto di confermare l'azione.",

    install_web_app_Opera: "L'installazione della app proWIN attualmente non è disponibile nel browser Opera.",
    install_web_app_Opera_Tablet_1: "Fare clic sul menu del browser",
    install_web_app_Opera_Tablet_2: "Seleziona \"Aggiungi a\" dal menu contestuale.",
    install_web_app_Opera_Tablet_3: "Ora puoi assegnare all'app un nome individuale, che verrà successivamente visualizzato sul tuo dispositivo insieme all'icona dell'app. Il nome dell'app viene memorizzato come preselezione.",
    install_web_app_Opera_Tablet_4: "Fare clic su Aggiungi.",

    install_web_app_Vivaldi_Desktop_1: "Klicke mit der rechten Maustaste auf die Registerkarte in der Registerkartenleiste.",
    install_web_app_Vivaldi_Desktop_2: "Seleziona 'Installare proWIN' nel menu contestuale.",
    install_web_app_Vivaldi_Tablet_1: "Fare clic sul menu del browser",
    install_web_app_Vivaldi_Tablet_2: "Seleziona \"Installa app\" o \"Aggiungi alla schermata iniziale\" dal menu contestuale.",
    install_web_app_Vivaldi_3: "Fai clic su Installa nella piccola finestra pop-up e ti chiedi di confermare l'azione.",

    install_web_app_SAFARI_1: "Apri la funzione di condivisione nel tuo browser.",
    install_web_app_SAFARI_2: "Fare clic su \"Vai alla schermata iniziale\"",
    install_web_app_SAFARI_3: "Ora puoi assegnare all'app un nome individuale, che verrà successivamente visualizzato sul tuo dispositivo insieme all'icona dell'app. Il nome dell'app viene memorizzato come preselezione.",
    install_web_app_SAFARI_4: "Fare clic su Aggiungi.",

    label_firstname: "Nome",
    label_lastname: "Cognome",
    label_birthday: "Data di nascita",
    label_title: "Appellativo",
    label_address: "Indirizzo",
    label_phone: "Telefono",
    label_email: "E-mail",
    label_company: "Ditta",
    label_street: "Strada",
    label_housenumber: "Numero civico",
    label_postalcode: "CAP",
    label_city: "Città",
    label_companyname: "Nome della ditta",
    label_div: "Altro",
    label_type_private: "Privato",
    label_type_company: "Attività commerciale",
    label_type_div: "Altro",
    label_note: "Appunti",
    label_products_desired: "Prodotti desiderati",
    label_contact_request: "Richiesta di contatto",
    label_contact_request_phone: "Telefono",
    label_contact_request_chat: "Chat",
    label_contact_request_post: "Posta",
    label_contact_request_email: "E-mail",
    label_contact_time: "Tempi di contatto",
    label_contact_time_weekly: "Nei giorni lavorativi",
    label_contact_time_weekend: "Il fine settimana",
    label_contact_time_morning: "di mattina",
    label_contact_time_afternoon: "di pomeriggio",
    label_contact_time_evening: "di sera",

    title_mr: "Signor",
    title_ms: "Signora",
    title_div: "Altro",

    network_status: "Sei offline",
    de_faq_home_headline: "Home bla bla bla ...",

    feature_later: 'Questa funzione verrà aggiunta in seguito',
    feature_only_for_vp: 'Questa funzionalità è riservata solo ai partner commerciali',

    texthing_error: "Si è verificato un errore durante il recupero dei dati oppure non sono disponibili in questa lingua.",

    employee_logged_as: "Hai effettuato l'accesso come dipendente. Seleziona un'opzione per continuare.",
    employee_to_switch_login: "Per cambiare login"
}