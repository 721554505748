import {Logger} from "@prowin/logger";

import {initFirebase} from "../firebase/firebase";
import {getWebAppInstalled, setIsBeforeInstallPromptAvailable, setWebAppInstalled} from "../../functions";
import {isBrowserOpera} from "../../components/InstallationInstruction/const";

export const registerServiceWorker = () => {
    if ('serviceWorker' in navigator) {

        window.addEventListener('load', () => {
            navigator.serviceWorker.register('/firebase-messaging-sw.js').then(_ => {
                Logger.log('SW with messaging registered:');
                initFirebase();
            }).catch(registrationError => {
                Logger.log('SW with messaging registration failed', registrationError);
            });

            // if ('getInstalledRelatedApps' in window.navigator) {
            //     navigator.getInstalledRelatedApps().then( apps => {
            //         console.log(apps,"@apps related")
            //     })
            // }

            if(getWebAppInstalled() === "cancelled"){
                setWebAppInstalled(null)
            }
        });

        //PWA install
        window.addEventListener("beforeinstallprompt", async (promptEvent: any) => {

            setIsBeforeInstallPromptAvailable();

            promptEvent.preventDefault();

            const handleInstall = async (event) => {

                promptEvent.prompt()

                const { outcome } = await promptEvent.userChoice;

                if (outcome === 'accepted') {
                    Logger.log("Installing web app.")
                    event.target.style.display = "none"
                    setWebAppInstalled("true")
                } else if (outcome === 'dismissed') {
                    // event.target.classList.add("Mui-disabled")
                    // event.target.disabled = true;
                    setWebAppInstalled("cancelled")
                }
            }

            // You can only call prompt() on the deferred event once. If the user dismisses it, you need to wait until the beforeinstallprompt event is fired again.
            setTimeout( () => {
                const installAppBtn = document.getElementById("install-app-btn");

                if (window.matchMedia('(display-mode: standalone)').matches) {
                    installAppBtn.style.display = "none"
                    return;
                }

                if(installAppBtn){
                    installAppBtn.addEventListener("click", handleInstall)
                }

                Logger.log(installAppBtn,"@install button")
            }, 5000)

        })
    }

// }
}