import {OAuth, OAuthConfig} from "@prowin/oauth2-client";

const oauthConfig: OAuthConfig = {
    clientId: process.env.OAUTH2_CLIENT_ID,
    clientSecret: process.env.OAUTH2_CLIENT_SECRET,
    accessTokenUri: `${process.env.OAUTH2_BASE_URI}${process.env.OAUTH2_ACCESS_TOKEN_ENDPOINT}`,
    authorizationUri: `${process.env.OAUTH2_BASE_URI}${process.env.OAUTH2_AUTHORIZATION_ENDPOINT}`,
    revokeUri: `${process.env.OAUTH2_BASE_URI}${process.env.OAUTH2_REVOKE_ENDPOINT}`,
    redirectUri: `${process.env.OAUTH2_REDIRECT_URI}${process.env.OAUTH2_REDIRECT_ENDPOINT}`,
    logoutRedirectUri: `${process.env.OAUTH2_LOGOUT_REDIRECT_URI}`,
    scopes: [process.env.OAUTH2_SCOPES],
    state: '', // will be generate in OAuth.createAuthIdentifier
    offlineModeAdditionalExpiredDays: 5, // 5 days is possible to use portal with expired token
    redirectPath: `${process.env.OAUTH2_REDIRECT_ENDPOINT}`,
    switchLoginPath: `${process.env.OAUTH2_SWITCH_LOGIN_PATH}`
}


// get oauth/user information from oauth
export function initOAuth(): Promise<OAuth> {

    const oauth = new OAuth(oauthConfig);

    return oauth.setup()
        .then( oauthClient => {
            // console.log(oauthClient, "@setting up oauth")
            return oauthClient
        })
        .catch( error => {
            // console.log(error ,"@refreshing token")
            return oauth.refreshToken().then( oauthClient => {
                // console.log(oauthClient, "@after refresh")
                return oauthClient
            }).catch( _ => {
                // console.log("@error occured after refresh")
                return Promise.reject(oauth)
            })
        });

}

// const oauthSetup = (oauthClient: OAuth) => {
//     if (oauthClient && oauthClient.isAuthenticated()) {
//           return oauthClient;
//     }
//
//     // return oauthClient;
// }

export function getOAuthValue(oauth: OAuth) {
    return {
        isTokenValid: oauth.isTokenValid(),
        isUserAuthenticated: oauth.isAuthenticated(),
        accessToken: (oauth && oauth.token && oauth.token.accessToken) ?? null,
        jwt: oauth.getJwtData(),
        impersonated: oauth?.token?.data.impersonated ?? localStorage.getItem("auth:data:impersonated") === "true",
        impersonator: oauth.token.data.impersonator ?? localStorage.getItem("auth:data:impersonator")
    }
}