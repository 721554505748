import type {PiralPlugin} from 'piral-core';

import type {Token} from "@prowin/oauth2-client/lib/types";

import {OAuthApiConfig, PiletOAuthApi} from "./types";
import {createActions} from "./actions";
import {getOAuthValue} from "./index";

export function createOAuthApi(config: OAuthApiConfig): PiralPlugin<PiletOAuthApi> {
    const {oauth} = config;

    return (context) => {
        context.defineActions(createActions(oauth));

        context.dispatch((state) => ({
            ...state,
            components: {
                ...state.components
            },
            oauth: getOAuthValue(oauth)
        }));

        const _doRefreshToken = () => {
            return oauth.refreshToken().then( response => {
                const oauthValue = getOAuthValue(response)

                context.emit("refresh-token", {...response.token})

                context.dispatch((state) => ({
                    ...state,
                    oauth: {...oauthValue}
                }));

                return response.token;
            })
        }

        return () => {
            return {
                isUserAuthenticated(): boolean {
                    return oauth.isAuthenticated()
                },

                doUserLogout(): void {
                    oauth.doLogout();
                },

                getOAuthToken(): Token {
                    return oauth.token
                },

                getUserData(): [key: string] {
                    return oauth.getJwtData()
                },

                doRefreshToken(): Promise<Token> {
                    return _doRefreshToken()
                },
                // TODO:Removed is token valid. Not helpful in pilets as value doesnt update
                isTokenValid: oauth.isTokenValid()
            }
        }
    }
}
