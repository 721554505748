import * as React from "react";

import * as ReactDOM from "react-dom/client";

import {createInstance, createStandardApi, getUserLocale, LocalizationMessages, Piral, setupLocalizer} from "piral";

import type {OAuth} from "@prowin/oauth2-client";

import LoadingIndicator from "../LoadingIndicator/LoadingIndicator";
import {languageDefault} from "../../languages/language";
import EmployeeLogin from "./EmployeeLogin";
import {createOAuthApi} from "../../lib/oauth2/create";

export function renderEmployeePortal(translations: LocalizationMessages,  oauthClient?: OAuth) {

    const instance = createInstance({
        plugins: [
            createOAuthApi({
                oauth: oauthClient
            }),
            ...createStandardApi({
                locale: setupLocalizer({
                    language: () => getUserLocale(
                        [languageDefault],
                        languageDefault,
                    ),
                    messages: {...translations}
                })
            }),
        ],
        state: {
            components: {
                Layout: EmployeeLogin,
                LoadingIndicator: () => (
                    <div dangerouslySetInnerHTML={{__html: LoadingIndicator}}/>
                ),
            },
        }
    });

    const root = ReactDOM.createRoot(document.getElementById("app") as HTMLElement);

    root.render(<Piral instance={instance} />);
}

export default renderEmployeePortal;