import {LanguageData, LocalizationMessages, Translations} from "piral-translate";
import {frenchTranslation} from "./french";
import {italianTranslation} from "./italian";
import {dutchTranslation} from "./dutch";

export const languages: Translations = {
    de: "Deutsch",
    fr: "Français",
    it: "Italiano",
    nl: "Nederlands"
}

export const languageDefault = Object.keys(languages).includes(navigator.language) ? navigator.language : 'de';

export const countries = {
    DE: "Deutschland",
    FR: "Frankreich",
    IT: "Italien",
    NL: "Niederlande",
    BE: "Belgien",
    CH: "Schweiz",
    AT: "Österreich",
    LU: "Luxemburg"
}

const defaultTranslation = {
    ...languages,
    ...countries,
    yes: "Ja",
    no: "Nein",
    ok: "OK",
    cancel: "Abbrechen",
    close: "Schließen",
    read: "Lesen",
    app_info: "prowin.app - Das Infoportal für Deine Vertriebstätigkeit",
    logout_success: "Sie haben erfolgreich abgemeldet",
    login_again: "Wieder anmelden",
    to_prowin_website:"Zu proWIN-Webseite wechseln",
    thank_you: "Vielen Dank für Ihren Besuch, wir wünschen Ihnen weiterhin viel Erfolg und Freude mit proWIN.",
    go_back_original_account: "Zurück zum Ursprungskonto",
    switch_account: "Konto wechseln",
    no_subaccount: "Kein Unterkonto verfügbar",
    select_country: "Land wählen",
    select_language: "Sprache wählen",
    change_country: "Land wechseln",
    change_language: "Sprache wechseln",

    error_back_to_home: "<small>Go back <a href=\"/\">to the dashboard</a>.</small>",
    error_not_found_title: "Page not Found",
    error_not_found_text: "Could not find the requested page. Are you sure it exists?",
    error_loading_title: "Loading Error",
    error_loading_text: "",
    error_unknown_title: "Unknown Error",
    error_unknown_text: "",
    error_occured: "Ein Fehler ist aufgetreten.",

    dashboard_title: "Herzlich willkommen bei proWIN international",
    dashboard_intro: "Microfrontend App Shell - Dashboard",
    demo_page_title: "Beispielseite",

    home: "Startseite",

    pwa_install: "Installieren",
    pwa_reinstall: "Bitte laden Sie die Seite neu, wenn Sie die Installationsschaltfläche wieder sehen möchten.",
    pwa_prompt_install: "Hol Dir die proWIN App.",
    pwa_enable_push: "Web-App zum Home-Bildschirm hinzufügen, um Push-Benachrichtigungen zu aktivieren",

    menu_label: "Menü",
    menu_home: "Startseite",
    menu_general_start_page: "Startseite",
    menu_general_faq: "Wissen",
    menu_general_information: "Wissen",
    menu_general_events: "Termine",
    menu_general_competitions: "Wettbewerbe",
    menu_general_products: "Produkte",
    menu_general_current: "Vertrieb",
    menu_product_information: "Produktinfo",
    menu_product_shop: "Shop",
    menu_product_complaints: "Reklamation",
    menu_general_myprowin: "mein proWIN",
    menu_myprowin_parties: "Partys",
    menu_myprowin_distribution_application: "Vertriebsantrag",
    menu_myprowin_invoices: "Rechnungen und Belege",
    menu_myprowin_reporting: "Reporting",
    menu_myprowin_distribution_website: "Vertriebswebseite",
    menu_myprowin_planning: "Planungstool",
    menu_elearning: "E-Learning",
    menu_media: "proWIN Media",

    menu_subline_product_marketing: "Werbemittel bestellen",
    menu_subline_product_info: "Allgemeine Produktinformationen",
    menu_subline_reporting: "Auswertungen aus meiner Struktur",
    menu_subline_reclamation: "Produkte reklamieren",
    menu_subline_shop: "Produkte bestellen",
    menu_subline_media: "Das große Videoportal",
    menu_subline_academy: "Mehr über die Beratertätigkeit erfahren",
    menu_subline_party_planning: "Meine nächste Party planen",
    menu_subline_structure: "Termine innerhalb meiner Struktur anlegen und verwalten",
    menu_subline_vp_webseite: "Eigene Vertriebspartnerwebseite bearbeiten",
    menu_subline_faq: "Wissensdatenbank zum Nachschlagen",

    menu_name_product_marketing: "Marketingshop",
    menu_name_product_info: "Produktinfos",
    menu_name_reporting: "Reporting",
    menu_name_blumer: "Blumer",
    menu_name_reclamation: "Reklamation",
    menu_name_shop: "Shop",
    menu_name_media: "proWIN Media",
    menu_name_academy: "Online-Akademie",
    menu_name_party_planning: "Partyplanung",
    menu_name_vp_webseite: "Meine VP-Seite",
    menu_name_structure: "Strukturtermine",
    menu_name_faq: "Wissen",

    user_menu_latest: "Aktuelles",
    user_menu_knowledge: "Wissen",
    user_menu_dark_mode: "Dark Mode",
    user_menu_light_mode: "Light Mode",
    user_menu_profile: "Profil",
    user_menu_tasks: "Aufgaben",
    user_menu_select_language: "Sprache wechseln",
    user_menu_my_account: "Mein Konto",
    user_menu_settings: "Einstellungen",
    user_menu_messages: "Nachrichten",
    user_menu_help: "Hilfe",
    user_menu_contact: "Kontakt",
    user_menu_logout: "Abmelden",
    user_menu_language: "Sprache",
    user_menu_country: "Land",
    user_menu_dashboard: "Dashboard",
    user_menu_salespartner_edit: "VP-Seitenedit",
    user_menu_app_install: "App Installieren",

    footer_copy: "{{currentYear}} proWIN international",

    oauth_logout_headline: "Logout",
    oauth_logout_text: "Logout done",

    link_imprint_href: "/impressum",
    link_imprint_label: "Impressum",
    link_privacy_href: "/datenschutz",
    link_privacy_label: "Datenschutz",
    link_help_href: "/help",
    link_help_label: "Hilfe",
    link_contact_href: "/contact",
    link_contact_label: "Kontakt",
    imprint_headline: "Impressum",
    privacy_headline: "Datenschutz",

    notifications_choice_title: "Push-Mitteilungen",
    notifications_ask_to_get_notificatons: "Möchten Sie über Push-Benachrichtigungen informiert werden?",
    notifications_ask_to_get_authorization: "Um Push-Benarichtigungen zu erhalten, müssen Sie dies in Ihrem Browser freigeben.",
    notifications_choice_intro: "Bitte wählen Sie zu welchen Themen ein Benachrichtigung gesendet werden soll:",
    notifications_choice_denied: "Die Benachrichtigung wurde blockiert. Bitte setzen Sie die Einstellungen zurück, um Push-Benachrichtigungen zu aktivieren.",
    notifications_activated: "Benachrichtigungen ist aktiviert.",
    notifications_turn_off: "Um keine Benachrichtigungen mehr zu erhalten, gehen Sie bitte zu den Einstellungen dieser Web-App auf Ihrem Gerät und deaktivieren Sie die Benachrichtigungen.",

    notification_group_general: "Test 1",
    notification_group_marketing: "Test 2",
    notification_group_other: "Test 3",

    install_web_app_General: "Die Installation der proWIN-App ist in diesem Browser derzeit nicht verfügbar. Bitte versuche einen anderen Browser.",
    install_web_app_dialog_title: "Installiere die proWIN App auf Deinem Gerät",

    install_web_app_Firefox: "Die proWIN-App-Installation ist derzeit im Firefox-Browser nicht verfügbar.",
    install_web_app_Firefox_Tablet_1: "Klicke auf das Browser-Menü",
    install_web_app_Firefox_Tablet_2: "Wähle 'Zum Startbildschirm hinzufügen' aus dem Kontextmenü.",
    install_web_app_Firefox_Tablet_3: "Klick auf die 'Startseite'",
    install_web_app_Firefox_3: "Klicke in dem kleinen Pop-up-Fenster auf Installieren und bitte Dich, Deine Aktion zu bestätigen.",

    install_web_app_Opera: "Die proWIN-App-Installation ist derzeit im Opera-Browser nicht verfügbar.",
    install_web_app_Opera_Tablet_1: "Klicke auf das Browser-Menü",
    install_web_app_Opera_Tablet_2: "Wähle 'Hinzufügen zu' aus dem Kontextmenü.",
    install_web_app_Opera_Tablet_3: "Du kannst nun der App einen individuellen Namen geben, der später zusammen mit dem App-Icon auf Deinem Gerät angezeigt wird. Als Vorauswahl ist der Name der App hinterlegt.",
    install_web_app_Opera_Tablet_4: "Klicke auf Hinzufügen.",

    install_web_app_Vivaldi_Desktop_1: "Klicke mit der rechten Maustaste auf den Tab in der Tableiste.",
    install_web_app_Vivaldi_Desktop_2: "Wähle 'proWIN installieren' aus dem Kontextmenü.",
    install_web_app_Vivaldi_Tablet_1: "Klicke auf das Browser-Menü",
    install_web_app_Vivaldi_Tablet_2: "Wähle 'App installieren' oder 'Zum Startbildschirm hinzufügen' aus dem Kontextmenü.",
    install_web_app_Vivaldi_3: "Klicke in dem kleinen Pop-up-Fenster auf Installieren und bitte Dich, Deine Aktion zu bestätigen.",
    install_web_app_Vivaldi_info_1: "Tablet/Mobilgeräte",
    install_web_app_Vivaldi_info_2: "Desktop/Laptop Geräte",

    install_web_app_SAFARI_1: "Öffne die Teilenfunktion in Deinem Browser.",
    install_web_app_SAFARI_2: "Klicke auf \"Zum Home-Bildschirm\"",
    install_web_app_SAFARI_3: "Du kannst nun der App einen individuellen Namen geben, der später zusammen mit dem App-Icon auf Deinem Gerät angezeigt wird. Als Vorauswahl ist der Name der App hinterlegt.",
    install_web_app_SAFARI_4: "Klicke auf Hinzufügen.",

    label_firstname: "Vorname",
    label_lastname: "Nachname",
    label_birthday: "Geburtstag",
    label_title: "Anrede",
    label_address: "Adresse",
    label_phone: "Telefon",
    label_email: "E-Mail",
    label_company: "Firma",
    label_street: "Straße",
    label_housenumber: "Hausnummer",
    label_postalcode: "PLZ",
    label_city: "Ort",
    label_companyname: "Firmenname",
    label_div: "Sonstige",
    label_type_private: "Privat",
    label_type_company: "Geschäftlich",
    label_type_div: "Sonstige",
    label_note: "Notizen",
    label_products_desired: "Wunschprodukte",
    label_contact_request: "Kontaktwunsch",
    label_contact_request_phone: "Telefon",
    label_contact_request_chat: "Chat",
    label_contact_request_post: "Post",
    label_contact_request_email: "E-Mail",
    label_contact_time: "Kontaktzeiten",
    label_contact_time_weekly: "Werktags",
    label_contact_time_weekend: "Wochenende",
    label_contact_time_morning: "vormittags",
    label_contact_time_afternoon: "nachmittags",
    label_contact_time_evening: "abends",

    title_mr: "Herr",
    title_ms: "Frau",
    title_div: "Sonstige",

    network_status: "Du bist offline",
    de_faq_home_headline: "Home bla bla bla ...",

    feature_later: 'Diese Funktion kommt später hinzu',
    feature_only_for_vp: 'Diese Funktion ist nur für Vertriebspartner',

    texthing_error: "Fehler beim Abrufen der Daten oder sie sind in dieser Sprache nicht verfügbar.",

    employee_logged_as: "Du bist als Mitarbeiter angemeldet. Bitte wähle eine Option, um fortzufahren.",
    employee_to_switch_login: "Zum Switch-Login",

}

const translations = [
    {
        language: 'de',
        translations: {
            ...defaultTranslation
        }
    },
    {
        language: 'fr',
        translations: {
            ...defaultTranslation,
            ...frenchTranslation
        }
    },
    {
        language: 'it',
        translations: {
            ...defaultTranslation,
            ...italianTranslation
        }
    },
    {
        language: 'nl',
        translations: {
            ...defaultTranslation,
            ...dutchTranslation
        }
    }

];


/**
 * load data for specific language
 * @param language
 * @param data
 */
export function loadLanguage(language: string, data: LanguageData): Promise<LanguageData> {
    return new Promise((resolve => {
        resolve(data);
    }))
}

/**
 * load all translation data (for example from server)
 * return json data with key en, de, ...
 */
export function loadTranslations(languages: string[]): Promise<LocalizationMessages> {
// TODO: Just pass translations as necessary like if it's available in the country selected or not
    return new Promise((resolve => {
        setTimeout(() => {
            let localizationMessages: LocalizationMessages = {};
            translations.forEach(translation => {
                if (languages.includes(translation.language)) {
                    localizationMessages[translation.language] = translation.translations;
                }
            });

            resolve(localizationMessages);
        }, 10);
    }));
}