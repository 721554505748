import {OAuth} from "@prowin/oauth2-client";
import {getClient, getSalespartner, SalespartnerInterface, getClients} from "@prowin/salespartner-client";

import {Logger} from "@prowin/logger";

export function loadSalespartner(oauth: OAuth): Promise<SalespartnerInterface> {

    Logger.setPrefix("Salespartner");

    if (oauth?.token && 'accessToken' in oauth?.token) {

        const jwtData = oauth.getJwtData();

        if (jwtData && 'sub' in jwtData) {

            const id = jwtData['sub'];

            return getSalespartner(id as number, oauth.token)
                .then(salespartner => {

                    return Promise.all([getClient(salespartner.client, oauth.token), getClients(oauth.token)]).then( ([clientData, clients ]) => {
                        salespartner.clientData = clientData;
                        salespartner.clients = clients;
                        return salespartner;
                    })
                });

        } else {
            return Promise.resolve(null)
        } // if else

    } else {
        return Promise.resolve(null)
    } // if else

} // loadSalespartner

