import * as React from 'react';

import {useTranslate} from "piral";

import {Alert, Link, Paper, Stack, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import LoopIcon from '@mui/icons-material/Loop';
import Container from "@mui/material/Container";
import HomeIcon from '@mui/icons-material/Home';

import {OAUTH2_LOGIN_URL, PROWIN_INTERNET_SITE} from "../../const";

const PROWIN_LOGO_INTERNATIONAL = require('../../assets/img/prowin-logo.svg');

const Logout = () => {
    const t = useTranslate()

    const goTo = (url: string) => {
        //Trick to redirect oauth to homepage then run settimeout
        window.location.href = process.env.OAUTH2_REDIRECT_URI;
        setTimeout( () => {
            window.location.href = url;
        }, 100)
    }

    return (
        <Container maxWidth={"xl"} sx={{ position: "relative"}}>
            <Box
                position={"absolute"}
                top={"25%"}
                left={"50%"}
                sx={{ marginTop: "60px", transform: "translateX(-50%)"}}
            >

                <Paper elevation={2} sx={{ padding: "2rem"}}>
                    <Stack gap={3}>
                        <Box component="img" src={PROWIN_LOGO_INTERNATIONAL} alt="proWIN" sx={{
                            height: "50px",
                        }}/>
                        <Alert severity={"success"}>
                            <Typography>{t("logout_success")}</Typography>
                        </Alert>
                        <Typography>{t("thank_you")}</Typography>
                        <Box display={"flex"} flexWrap={"wrap"} justifyContent={"space-between"} gap={2}>
                            <Link onClick={() => goTo(OAUTH2_LOGIN_URL)} sx={{ "&:hover": { cursor: "pointer"},}} underline={"none"}>
                                <Typography sx={{ display: "flex", alignItems: "flex-base" }}>
                                    <LoopIcon fontSize={"small"} sx={{ marginRight: "4px"}}/> {t("login_again")}
                                </Typography>
                            </Link>
                            <Link onClick={() => goTo(PROWIN_INTERNET_SITE)} sx={{ "&:hover": { cursor: "pointer"},}} underline={"none"}>
                                <Typography sx={{ display: "flex", alignItems: "flex-base" }}>
                                    <HomeIcon fontSize={"small"} sx={{ marginRight: "4px"}}/> {t("to_prowin_website")}
                                </Typography>
                            </Link>
                        </Box>
                    </Stack>
                </Paper>
            </Box>
        </Container>
    );
};

export default Logout;