import {Logger} from "@prowin/logger";

import { initializeApp } from 'firebase/app'
import { getMessaging, getToken } from "firebase/messaging";

export const firebaseConfig = {
    apiKey: process.env.FIREBASE_APIKEY,
    authDomain: process.env.FIREBASE_AUTHDOMAIN,
    projectId: process.env.FIREBASE_PROJECTID,
    storageBucket: process.env.FIREBASE_STORAGEBUCKET,
    messagingSenderId: process.env.FIREBASE_MESSAGINGSENDERID,
    appId: process.env.FIREBASE_APPID
};

export const localStorageRegistrationPhaseKey = 'notification_registration_phase';
export const localStorageRegistrationTokenKey = 'notification_registration_token';

export const initFirebase = () => {
    initializeApp(firebaseConfig);
    console.log("Firebase has been initialized.")
}

export enum RegistrationPhase {
    REQUIRED_AUTH = 1,
    NO_TOKEN = 2,
    TOKEN_AVAILABLE = 3,
    NOTIFICATION_DENIED = 4
}

export const isServiceWorkerRegistered = () => {
    if ('serviceWorker' in navigator) {
        return navigator.serviceWorker.getRegistration().then((registration) => {
            return !!registration;
        }).catch( er => {
            console.log("Error registering service worker. " + er)
            return false;
        })
    }
}

export const requestPermission = () => {
    console.log('Requesting notification permission...');
    return Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
            console.log('Notification permission granted.');
            return true
        }
        return false
    }).catch(_ => {
        return false;
    })
}

export const registrationToken = () => {
    const messaging = getMessaging();

    getToken(messaging,{ vapidKey: process.env.FIREBASE_VAPIDKEY }).then((currentToken) => {
        if (currentToken) {
            // Send the token to your server and update the UI if necessary
            // ...
            console.log("Current messaging token: " + currentToken)
            // setTokenFound(true)
        } else {
            // Show permission request UI
            console.log('No registration token available. Request permission to generate one.');
            // setTokenFound(false)
            // ...
        }
    }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
        // ...
    });

}

export const getPushNotificationToken = async (registration: ServiceWorkerRegistration) => {
    const messaging = getMessaging();

    return getToken(messaging,{vapidKey: process.env.FIREBASE_VAPIDKEY})
        .then((currentToken) => {
            if (currentToken) {
                // Send the token to your server and update the UI if necessary
                console.log('current messaging token', currentToken);
                localStorage.setItem(localStorageRegistrationTokenKey, currentToken)
                localStorage.setItem(localStorageRegistrationPhaseKey, RegistrationPhase.TOKEN_AVAILABLE.toString())

                // ToDo: send token + options to Salespartner-MS

                return RegistrationPhase.TOKEN_AVAILABLE;
            } else {
                // Show permission request UI
                Logger.debug('No registration token available. Request permission to generate one.');
                localStorage.removeItem(localStorageRegistrationTokenKey);
                localStorage.setItem(localStorageRegistrationPhaseKey, RegistrationPhase.NO_TOKEN.toString())
                return RegistrationPhase.NO_TOKEN;
            }
        }).catch((err) => {
            Logger.debug('An error occurred while retrieving token. ', err);
            localStorage.removeItem(localStorageRegistrationTokenKey);
            localStorage.setItem(localStorageRegistrationPhaseKey, RegistrationPhase.REQUIRED_AUTH.toString())
            return RegistrationPhase.REQUIRED_AUTH;
        });
}