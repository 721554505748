import type {OAuth} from "@prowin/oauth2-client";
import type {GlobalStateContext} from "piral-core";
import {Logger} from "@prowin/logger";
import {Token} from "@prowin/oauth2-client/lib/types";

import {getOAuthValue} from "./index";

export function createActions(oauth: OAuth) {

    return {
        doUserLogout(ctx: GlobalStateContext): void {
            try {
                oauth.doLogout();
                setTimeout(()=> {
                    ctx.emit('user-logout',{});
                }, 0);
            } catch (error) {
                Logger.error("Error in doUserLogout");
            }
        },

        refreshToken: (context: GlobalStateContext): Promise<Token> => {

            return oauth.refreshToken().then(response => {
                const oauthValue = getOAuthValue(response)

                context.emit("refresh-token", {...response.token})

                context.dispatch((state) => ({
                    ...state,
                    oauth: {...oauthValue}
                }));

                return response.token;
            })
        }
    };
}
