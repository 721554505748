import * as React from 'react';
import * as ReactDOM from 'react-dom';

import {OAuth, Phase} from "@prowin/oauth2-client";
import {SalespartnerInterface} from "@prowin/salespartner-client";

import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import AlertTitle from '@mui/material/AlertTitle';

import {loadSalespartner} from "./lib/salespartner";
import {languageDefault, loadTranslations} from "./languages/language";
import {initOAuth} from "./lib/oauth2";
import {renderLogoutPortal} from "./components/Logout/logoutPortal";

import {registerServiceWorker} from "./lib/service-worker/sw";
import renderEmployeePortal from "./components/Employee/employeePortal";
import {OAUTH2_LOGIN_URL} from "./const";

registerServiceWorker();

initOAuth()
    .then((oauthClient: OAuth) => {
        const jwtData =  oauthClient?.getJwtData();

        if(oauthClient?.phase === Phase.PHASE_LOGOUT_PAGE){
            Promise.all([loadTranslations([languageDefault]),])
                .then(([translations]) => {
                    renderLogoutPortal(translations)
                });
        } else if (jwtData && 'pw_user_type' in jwtData && jwtData['pw_user_type'] !== 'salespartner' && oauthClient.token.data.impersonated === "false" && oauthClient.isAuthenticated()) {
            Promise.all([loadTranslations([languageDefault]),])
                .then(([translations]) => {
                    renderEmployeePortal(translations, oauthClient)
                });
        } else {
            handleLoadingSalespartner(oauthClient)
        }
    })
    .catch( oauthError => {
        errorRenderer(oauthError)
    });


const handleLoadingSalespartner = (oauthClient: OAuth) => {
    loadSalespartner(oauthClient)
        .then((salespartner: SalespartnerInterface) => {
            Promise.all([loadTranslations(salespartner ? salespartner.clientData.languages : [languageDefault]), import("./portal")])
                .then(([translations, {renderPortal}]) => {

                    if ((oauthClient.isAuthenticated() && salespartner.id) || (oauthClient && oauthClient.phase === Phase.PHASE_LOGOUT_PAGE)) {
                        renderPortal(translations, oauthClient, salespartner);
                    }
                });

        }).catch( error => {

        if(error?.status === 401){
            oauthClient?.clearToken()

            window.location.href = process.env.OAUTH2_REDIRECT_URI;

            setTimeout( () => {
                window.location.href = OAUTH2_LOGIN_URL;
            }, 100)
        }

        errorRenderer(error)
    });
}

const errorRenderer = (error: Error) => {
    ReactDOM.render(
        <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            <Typography>{error?.message ?? error.toString()}</Typography>
        </Alert>,
        document.getElementById('app')
    )
}