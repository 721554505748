import {INSTALL_PROMPT, KB_FAQ_URL, LOCAL_THEME_MODE, WEB_APP_INSTALLED} from "./const";

export const localThemeMode = localStorage.getItem(LOCAL_THEME_MODE);

export const getKbFaqUrl = (language) => {
    let searchTranslation;

    switch (language){
        case "it":
            searchTranslation = KB_SEARCH_TRANSLATION.IT
            break;
        case "nl":
            searchTranslation = KB_SEARCH_TRANSLATION.NL
            break;
        case "fr":
            searchTranslation = KB_SEARCH_TRANSLATION.FR
            break;
        case "de":
            searchTranslation = KB_SEARCH_TRANSLATION.DE
            break;

        default:
            searchTranslation = KB_SEARCH_TRANSLATION.DE
    }

    return `${KB_FAQ_URL(language)}/${searchTranslation}`;
}

export enum KB_SEARCH_TRANSLATION {
    IT = "ricerca",
    NL = "zoeken",
    FR = "recherche",
    DE = "suche"
}

export const getWebAppInstalled = () => {
    return localStorage.getItem(WEB_APP_INSTALLED);
}

export const  setWebAppInstalled = (value: "true" | "false" | "cancelled" | null) => {
    localStorage.setItem(WEB_APP_INSTALLED, value);
}

export const setIsBeforeInstallPromptAvailable = () => localStorage.setItem(INSTALL_PROMPT, true.toString());
export const getIsBeforeInstallPromptAvailable = () => localStorage.getItem(INSTALL_PROMPT);
