import * as React from 'react';

import {useActions, useTranslate} from "piral";

import 'material-icons/iconfont/material-icons.scss'

import Box from "@mui/material/Box";
import {Link, Paper, Stack, Typography} from "@mui/material";
import Container from "@mui/material/Container";
import InputIcon from "@mui/icons-material/Input";

const PROWIN_LOGO_INTERNATIONAL = require('../../assets/img/prowin-logo.svg');

const EmployeeLogin = () => {
    const t = useTranslate()
    const { doUserLogout } = useActions();

    return (
        <Container maxWidth={"xl"} sx={{ position: "relative"}}>
            <Box
                position="absolute"
                top={"25%"}
                left={"50%"}
                sx={{ marginTop: "60px", transform: "translateX(-50%)"}}
            >
                <Paper elevation={2} sx={{ padding: "2rem"}}>
                    <Stack gap={3}>
                        <Box component="img" src={PROWIN_LOGO_INTERNATIONAL} alt="proWIN" sx={{
                            height: "50px",
                        }}/>

                        <Typography>{t("employee_logged_as")}</Typography>

                        <Box display={"flex"} flexWrap="wrap" justifyContent={"space-between"} gap={2}>
                            <Link href={process.env.OAUTH2_BASE_URI} sx={{ "&:hover": { cursor: "pointer"},}} underline={"none"}>
                                <Typography sx={{display: "flex", alignItems: "flex-base", justifyContent: "center"}}>
                                    <i className="material-icons pr-1" style={{ marginRight: "4px"}}>swap_horiz</i>
                                    {t("employee_to_switch_login")}
                                </Typography>
                            </Link>

                            <Link onClick={() => doUserLogout()} sx={{"&:hover": {cursor: "pointer"},}} underline={"none"}>
                                <Typography sx={{ display: "flex", alignItems: "flex-base" }}>
                                    <InputIcon fontSize="small" sx={{ marginRight: "4px"}}/>
                                    {t("user_menu_logout")}
                                </Typography>
                            </Link>
                        </Box>
                    </Stack>
                </Paper>
            </Box>
        </Container>
    );
};

export default EmployeeLogin;